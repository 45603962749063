b i{
    font-weight: bold;
}
#toc a{
    color: #B11116;
}
.descPage
{
    padding: 10px;
   
}
Button{
    font-family: var(--font);
}

.descImg
{
    text-align: center;
    width: 100%;
    height: 450px;
    /* margin:0 auto; */
    /* padding:10px 20px; */
    background-color: #EEFBF9;


}
.descTitle
{
    font-weight: 600;
    font-size: 18px;
    color: #9099e0;
    margin-top: 5px;
   
}
.descAuthor
{
    font-weight: 600;
    white-space: normal;
    font-size: 14px;
    margin-top: 7px;
    
}
.descPub
{
    color: #999999;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0px;
    
}
.buttonList
{
    padding-right: 3px;
    padding-bottom: 10px;
}

.descHead
{
    
    font-weight: bold;
    /* margin-top: 20px; */
}

.description
{
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align:justify
    
}
.btntext{
    color: #ffff;
}

.coverbg{
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    height: 40%;
    width: 100%;
    filter: blur(3px);
    background-color: rgb(218, 218, 218);
    animation:example 4s infinite;

}
.dcover_cntiner{
    width: 80%;
    height: 100%;
    background-color: rgb(218, 218, 218);
    animation:example 10s infinite;
}
.dcover{
    max-width: 70%;
    background-color: #EEFBF9;
    border-radius:5px;
    height:300px ;
    box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    z-index: 1;
    
  

}
.panal{
    color: #000;
    background-color: #F0F0F0;
    font-family: var(--font);
}
.comment{
    display: flex;
    flex-direction: column;
}
.bookinfo{
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;
     border-top: rgb(218, 216, 216) solid 1px;
     border-bottom: rgb(218, 216, 216) solid 1px;
     margin-bottom: 10px;

}
.info-box{
    display: flex;
    width: 33%;
    align-items: center;
    flex-direction: column;
}
.with1{
    display: flex;
    width: 1px;
    margin-left: 10px;
   height: 45px;
   background-color:rgb(218, 216, 216);
}
.infotitel{
    margin-bottom: 0px;
    font-family: var(--font);
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(88, 87, 87);
}
.info-food{
    margin-bottom: 0px;
    font-family: var(--font);
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
    color: #000;
}

.info-icon{
   padding: 10px;
    font-size: 13px;
    color: #000;
}
.reader-card{
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    border: rgb(218, 216, 216) solid 1px;
    flex-direction: row;
}
.reader-card-info{
   display: flex;
   padding-left: 10px;
   flex-direction:column;
}
.reader-card-info h3{
    margin-bottom: 0px;
    font-family: var(--font);
    margin-top: 3px;
    letter-spacing: 0.5px;
    font-weight: bold;
    font-size: 16px;
}
.reader-card-info P{
    margin-bottom: 0px;
    font-family: var(--font);
    letter-spacing: 0.5px;
    font-weight:normal;
    font-size: 14px;
}
.reader-card-info P span{
    color: #B11116 ;
}
.cls-btn {
    background-color: #B11116;
    border: 1px solid #B11116;
    color: #FFF;
    font-weight: 700;
}
.icon-continer{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 25px;
}
.curren{
    text-align: center;
    margin-bottom: 0px;
    color:#B11116;
    font-family: var(--font);
    font-size: 12px;
    letter-spacing: 1px;
}
.icons{
    display: flex;
    flex-direction: row;
    background-color: #B11116;
    border-radius: 5;
}
.readr-icon{
    font-size: 13px;
    color: #fff;
    padding: 10px;
}
.reader-btn{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #B11116;
}
.reader-btn-buy{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #747474;
}

.reader-btn P{
    margin-bottom: 0px;
    color:#fff;
    font-family: var(--font);
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
}
.reader-btn-buy P{
    margin-bottom: 0px;
    color:#fff;
    font-family: var(--font);
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
}
.icon-continer h4{
    text-align: center;
    margin-bottom: 0px;
    color:#B11116;
    font-family: var(--font);
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
}
.notifibtn{
    background-color: #B11116;
    text-align: center;
    color: #fff;
    font-family: var(--font);
    font-size: 10px;
    padding: 5px 2px 5px 2px;
    margin-bottom: 0px;
    margin-right: 3px;

}
.cardicon{
    color:#080808;

}
.submit-btn{
    display: flex;
    padding: 5px;
    align-items: center;
    width: 30%;
    justify-content: center;
    background-color: #B11116;
}
.submit-btn p{
    margin-bottom: 0px;
    color:#fff;
    font-family: var(--font);
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
}

@keyframes example {
    0%   {background-color: rgb(221, 217, 217);}
    25%  {background-color: rgb(177, 177, 174);}
    50%  {background-color: rgb(213, 213, 216);}
    100% {background-color: rgb(168, 172, 168);}
  }
.ant-card-body {
    padding: 10px !important;
    font-size: 16px;;
}  

.centered-icon-btn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-left:2px;
}

.centered-icon-btnr {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right:2px;
}

.button-container {
    position: relative;
    height: 100vh;
}